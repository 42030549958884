import axios from "axios";
import { deviceModel, repairCostModel } from "../../quoter/model/quoterModel";

const url = import.meta.env.VITE_REACT_APP_BACKEND_URL;

export async function doGetOrder(orderId: string) {
  const response = await axios.get(`/api/orders/${orderId}`, {
    params: {
      populate: {
        repair_costs: {
          populate: ["repair_type", "repair_subtype"],
        },
        device: true,
        order_status: true,
      },
    },
  });

  const formattedResponse = {
    id: response.data.data.id,
    ...response.data.data.attributes,
    device: {
      id: response.data.data.attributes.device.data.id,
      ...response.data.data.attributes.device.data.attributes,
    },
    order_status: {
      id: response.data.data.attributes.order_status.data.id,
      ...response.data.data.attributes.order_status.data.attributes,
    },
    repair_costs: response.data.data.attributes.repair_costs.data.map((rc) => ({
      id: rc.id,
      cost: rc.attributes.cost,
      createdAt: rc.attributes.createdAt,
      updatedAt: rc.attributes.updatedAt,
      repair_type: rc.attributes.repair_type.data
        ? {
            id: rc.attributes.repair_type.data.id,
            ...rc.attributes.repair_type.data.attributes,
          }
        : null,
      repair_subtype: rc.attributes.repair_subtype.data
        ? {
            id: rc.attributes.repair_subtype.data.id,
            ...rc.attributes.repair_subtype.data.attributes,
          }
        : null,
    })),
  };

  return formattedResponse;
}

export async function doGetOrderStatuses() {
  const response = await axios.get(`/api/order-statuses`);
  const formattedResponse = response.data.data.map((orderStatus: any) => ({
    id: orderStatus.id,
    ...orderStatus.attributes,
  }));

  return formattedResponse;
}

export async function doGetOrders({
  page = 1,
  pageSize = 10,
  sort = "id:desc",
  withCount = true,
  fields = "*",
  populate = "*",
  locale = "",
  startDate = "",
  endDate = "",
  documentNumber = "",
  device = "",
  deviceType = "",
  manufacturer = "",
  id = "",
} = {}) {
  const params = new URLSearchParams({
    ...(sort && { sort }),
    "pagination[withCount]": String(withCount),
    "pagination[page]": String(page),
    "pagination[pageSize]": String(pageSize),
    ...(fields && { fields }),
    ...(populate && { populate }),
    ...(locale && { locale }),
    ...(startDate && { "filters[createdAt][$gte]": startDate }),
    ...(endDate && { "filters[createdAt][$lte]": endDate }),
    ...(documentNumber && { "filters[documentNumber]": documentNumber }),
    ...(id && { "filters[id]": id }),
    ...(device && {
      "filters[device]": device,
    }),
    ...(deviceType && {
      "filters[device][device_type]": deviceType,
    }),
    ...(manufacturer && {
      "filters[device][manufacturer]": manufacturer,
    }),
  });

  const response = await axios.get(`/api/orders?${params}`);

  const transformedData = transformOrdersResponse(response.data);
  return transformedData;
}

export async function doGetDevices(searchTerm: string) {
  const response = await axios.get<any>(
    `${url}/api/devices/search?searchTerm=${encodeURIComponent(searchTerm)}`,
  );
  const devicesData = response.data.data.map((device: deviceModel) => ({
    id: device.id,
    attributes: {
      technicalName: device.attributes.technicalName.toString(),
      commercialName: device.attributes.commercialName.toString(),
    },
  }));

  return devicesData;
}

export const updateOrderAPI = async (orderId: string, orderData: object) => {
  const response = await axios.put(`/api/orders/${orderId}`, {
    data: orderData,
  });
  return response.data;
};

export const cancelOrderAPI = async (orderId: string) => {
  const response = await axios.put(`/api/orders/${orderId}`, {
    data: { order_status: 2 }, // cancelled
  });
  return response.data;
};

export const confirmOrderAPI = async (orderId: string) => {
  const response = await axios.get(`/api/orders/${orderId}/confirm`);
  return response.data;
};

function transformOrdersResponse(response: any) {
  const data = response.data.map((order: any) => ({
    id: order.id,
    ...order.attributes,
    orderStatus: order.attributes.order_status.data
      ? order.attributes.order_status.data.attributes.name
      : null,
    repairCosts: order.attributes.repair_costs.data.map(
      (rc: repairCostModel) => rc.attributes.cost,
    ),
    device: order.attributes.device.data
      ? {
          id: order.attributes.device.data.id,
          technicalName: order.attributes.device.data.attributes.technicalName,
          commercialName:
            order.attributes.device.data.attributes.commercialName,
        }
      : null,
  }));

  const pagination = response.meta.pagination;

  return {
    data,
    pagination,
  };
}
