import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Paginator } from "primereact/paginator";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAuth } from "../../../app/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RoutesDefinitions from "../../../routes-definition";
import { useIsMobile } from "../../../utils/responsiveDesign";
import {
  fetchCancelOrder,
  fetchConfirmOrder,
  fetchDevices,
  loadOrders,
  selectOrdersState,
} from "../ordersSlice";
import OrderCard from "./orderCard";
import OrdersSearchPanel from "./orderSearchPanel";
import OrdersTable from "./ordersTable";
import { selectQuoterState } from "../../quoter/quoterSlice";

function OrdersComponent() {
  const navigate = useNavigate();
  const authState = useAppSelector(selectAuth);
  const isAdmin = authState.user?.role === "Technic Admin";
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const { orders, devices } = useSelector(selectOrdersState);

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10); // Default pageSize
  const [searchId, setSearchId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [dateRange, setDateRange] = useState(null);
  const [searchDocumentNumber, setSearchDocumentNumber] = useState(undefined);
  const [searchDevice, setSearchDevice] = useState(null);
  const searchRef = useRef<NodeJS.Timeout | null>(null);
  const { selectedDeviceType, selectedManufacturer } =
    useSelector(selectQuoterState);

  const [filteredDevices, setDevices] = useState<
    Array<{ label: string; value: string }>
  >([]);

  const onViewDetails = (orderId: string) => {
    navigate(
      `${RoutesDefinitions.order.orderDetail.replace(":orderId", orderId)}`,
    );
  };

  const onEditOrder = (orderId: string) => {
    navigate(
      `${RoutesDefinitions.order.orderDetail.replace(":orderId", orderId)}`,
    );
  };

  const confirmOrder = async (orderId: string) => {
    try {
      await dispatch(fetchConfirmOrder(orderId)).unwrap();
      toast.success(t("order.orderConfirmSuccess"));
      let pages = first / rows + 1;
      dispatch(loadOrders({ page: pages, pageSize: rows }));
    } catch (err) {
      // Handle error
    }
  };

  const cancelOrder = async (orderId: string) => {
    try {
      await dispatch(fetchCancelOrder(orderId)).unwrap();
      toast.success(t("order.orderCancelSuccess"));
      let pages = first / rows + 1;
      dispatch(loadOrders({ page: pages, pageSize: rows }));
    } catch (err) {
      // Handle error
    }
  };

  const showConfirmOrder = (orderId: string) => {
    confirmDialog({
      message: t("order.confirmOrderMessage"),
      header: t("order.confirmOrderConfirmation"),
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-confirm",
      acceptLabel: t("Yes"),
      rejectLabel: t("No"),
      accept: () => confirmOrder(orderId),
      reject: () => {},
    });
  };

  const showConfirmCancelOrder = (orderId: string) => {
    confirmDialog({
      message: t("order.cancelOrderMessage"),
      header: t("order.cancelOrderConfirmation"),
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      acceptLabel: t("Yes"),
      rejectLabel: t("No"),
      accept: () => cancelOrder(orderId),
      reject: () => {},
    });
  };

  useEffect(() => {
    let pages = first / rows + 1;
    dispatch(loadOrders({ page: pages, pageSize: rows }));
  }, [dispatch, first, rows]);

  const handleSearch = () => {
    setFirst(0);

    const startDate =
      dateRange && dateRange[0]
        ? (dateRange[0] as Date).toISOString()
        : undefined;
    let endDate =
      dateRange && dateRange[1]
        ? (dateRange[1] as Date).toISOString()
        : undefined;

    if (endDate) {
      const endDatePlusOne = new Date(endDate);
      endDatePlusOne.setDate(endDatePlusOne.getDate() + 1); // Add one day
      endDate = endDatePlusOne.toISOString();
    }

    dispatch(
      loadOrders({
        page: 1,
        pageSize: rows,
        id: searchId,
        documentNumber: searchDocumentNumber,
        device: searchDevice ? searchDevice.value : null,
        deviceType: selectedDeviceType ? selectedDeviceType.value : null,
        manufacturer: selectedManufacturer ? selectedManufacturer.value : null,
        startDate,
        endDate,
      }),
    );
  };

  useEffect(() => {
    if (devices && devices.length > 0) {
      const formattedDevices: Array<{ label: string; value: string }> =
        devices.map((device) => ({
          label: device.attributes.commercialName,
          value: device.id,
        }));
      setDevices(formattedDevices);
    }
  }, [devices]);

  useEffect(() => {
    if (searchTerm.length >= 2) {
      if (searchRef.current) {
        clearTimeout(searchRef.current);
      }
      searchRef.current = setTimeout(() => {
        dispatch(fetchDevices({ searchTerm }));
      }, 500);
    }
  }, [searchTerm, dispatch]);

  return (
    <>
      <ConfirmDialog />

      <div className="p-fluid">
        <div>
          <div className="mb-4">
            <h2>{t("Orders")}</h2>
          </div>
          <div>
            <OrdersSearchPanel
              searchId={searchId}
              setSearchId={setSearchId}
              searchDocumentNumber={searchDocumentNumber}
              setSearchDocumentNumber={setSearchDocumentNumber}
              searchDevice={searchDevice}
              setSearchDevice={setSearchDevice}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              dateRange={dateRange}
              setDateRange={setDateRange}
              handleSearch={handleSearch}
              filteredDevices={filteredDevices}
            />
          </div>
        </div>
        {orders && orders.data && (
          <>
            {isMobile ? (
              orders.data.map((order) => (
                <OrderCard
                  key={order.id}
                  order={order}
                  onViewDetails={onViewDetails}
                  onEditOrder={onEditOrder}
                  onConfirmOrder={showConfirmOrder}
                  showConfirmCancelOrder={showConfirmCancelOrder}
                  isAdmin={isAdmin}
                />
              ))
            ) : (
              <OrdersTable
                orders={orders}
                onViewDetails={onViewDetails}
                onEditOrder={onEditOrder}
                onConfirmOrder={showConfirmOrder}
                showConfirmCancelOrder={showConfirmCancelOrder}
                isAdmin={isAdmin}
              />
            )}
            <Paginator
              rows={orders.pagination.pageSize}
              totalRecords={orders.pagination.total || 1}
              onPageChange={(e) => {
                setFirst(e.first);
                setRows(e.rows);
              }}
              //rowsPerPageOptions={[5, 10, 20]}
              first={(orders.pagination.page - 1) * orders.pagination.pageSize}
              template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            />
          </>
        )}
      </div>
    </>
  );
}

export default OrdersComponent;
