// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios, { AxiosError } from "axios";
import { Auth } from "../lib/authService";
import { AuthUser } from "../models/auth-user";

const backendUrl = import.meta.env.VITE_REACT_APP_BACKEND_URL;

type AuthResponse = {
  jwt: string;
  user: AuthUser;
};

export async function doLogin(access_params: string): Promise<AuthResponse> {
  try {
    const response = await axios.get<AuthResponse>(
      `${backendUrl}/api/auth/google/callback${access_params}`,
    );
    return response.data;
  } catch (err: unknown | AxiosError) {
    throw err;
  }
}

export async function refreshToken(token: string) {
  const response = await axios.post<Auth>(
    "auth/refresh-token",
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
}

export async function doLogOut() {
  return Promise.resolve();
}
