import { AuthUser } from "../models/auth-user";

export type Auth = {
  access_token: string;
  refresh_token: string;
};

export class AuthService {
  // eslint-disable-next-line no-use-before-define
  private static instance: AuthService;

  private KEY = "auth";
  private USER_KEY = "user";

  private auth: Auth | null = null;
  private user: AuthUser | null = null;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance() {
    if (!this.instance) {
      this.instance = new AuthService();
    }
    return this.instance;
  }

  getAuthFromLS(): Auth | null {
    if (this.auth) {
      return this.auth;
    }

    if (window && window.localStorage) {
      const authLS = localStorage.getItem(this.KEY);
      try {
        if (authLS) {
          this.auth = JSON.parse(authLS) as Auth;
          return this.auth;
        }
      } catch (err) {
        this.auth = null;
      }
    }
    return null;
  }

  getUserFromLS(): AuthUser | null {
    if (this.user) {
      return this.user;
    }

    if (window && window.localStorage) {
      const userLS = localStorage.getItem(this.USER_KEY);
      try {
        if (userLS) {
          this.user = JSON.parse(userLS) as AuthUser;
          return this.user;
        }
      } catch (err) {
        this.user = null;
      }
    }
    return null;
  }

  setAuthToLS(auth: Auth, user: AuthUser): void {
    if (auth) {
      const authString = JSON.stringify(auth);
      localStorage.setItem(this.KEY, authString);
      this.auth = auth;
    }

    if (user) {
      const userString = JSON.stringify(user);
      localStorage.setItem(this.USER_KEY, userString);
      this.user = user;
    }
  }

  setUserToLS(user: AuthUser): void {
    if (user) {
      const userString = JSON.stringify(user);
      localStorage.setItem(this.USER_KEY, userString);
      this.user = user;
    }
  }

  deleteAuthFromLS(): void {
    localStorage.removeItem(this.KEY);
    localStorage.removeItem(this.USER_KEY);
    this.auth = null;
    this.user = null;
  }

  getLocalRefreshToken(): string | undefined {
    return this.auth?.refresh_token;
  }

  updateLocalAccessToken(accessToken: string) {
    const auth = {
      access_token: accessToken,
      refresh_token: this.auth?.refresh_token || "",
    };

    this.setAuthToLS(auth, this.user!);
  }
}
