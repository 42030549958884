import { useFormikContext } from "formik";
import { Fieldset } from "primereact/fieldset";
import { InputNumber } from "primereact/inputnumber";
import { RadioButton } from "primereact/radiobutton";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../app/auth/authSlice";
import { useAppDispatch } from "../../../app/hooks";
import { FormValuesModel } from "../model/quoterModel";
import { fetchRepairCosts, selectQuoterState } from "../quoterSlice";

interface RepairCostsComponentProps {
  repairTypeId: string;
  repairSubtypeId?: string;
  deviceId: string;
}

const RepairCostsComponent: React.FC<RepairCostsComponentProps> = ({
  repairTypeId,
  repairSubtypeId,
  deviceId,
}) => {
  const { setFieldValue, values } = useFormikContext<FormValuesModel>();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const authState = useSelector(selectAuth);
  const userMargin = authState.user?.margin || 0;
  const repairCost = useSelector(selectQuoterState).repairCost;
  const [margin, setMargin] = useState<number>(userMargin || 0);
  const [selectedOption, setSelectedOption] = useState("margin");

  useEffect(() => {
    dispatch(fetchRepairCosts({ repairTypeId, deviceId, repairSubtypeId }));
  }, [dispatch, repairTypeId, deviceId, repairSubtypeId]);

  useEffect(() => {
    if (repairCost) {
      const cost = parseFloat(repairCost.attributes.cost);
      const finalCost =
        selectedOption === "margin" ? cost + (cost * margin) / 100 : cost;
      setFieldValue("finalCost", finalCost.toString());
    }
  }, [repairCost, margin, selectedOption, setFieldValue]);

  const handleMarginChange = (e: any) => {
    const newMargin = parseFloat(e.value) || 0;
    setMargin(newMargin);
  };

  const handleFinalPriceChange = (e: any) => {
    if (selectedOption === "customPrice") {
      setFieldValue("finalCost", parseFloat(e.value));
    }
  };

  return (
    <div>
      <div className="flex flex-column gap-2 mb-3">
        <label htmlFor="repairCost">{t("quoter.repairCost")}:</label>
        <InputNumber
          inputId="repairCost"
          value={repairCost?.attributes.cost}
          disabled
          mode="currency"
          currency="ARS"
          locale="es-AR"
        />
      </div>

      <Fieldset legend={t("quoter.priceCalculate")}>
        <div className="flex align-items-center gap-2">
          <div className="flex align-items-center gap-2 mb-3">
            <RadioButton
              inputId="margin"
              name="priceOption"
              value="margin"
              onChange={(e) => setSelectedOption(e.value)}
              checked={selectedOption === "margin"}
            />
            <label htmlFor="margin">{t("userInfo.margin")}:</label>
          </div>
          <div className="flex align-items-center gap-2 mb-3">
            <InputNumber
              inputId="marginValue"
              value={margin}
              onChange={(e) => handleMarginChange(e)}
              disabled={selectedOption !== "margin"}
              mode="decimal"
              minFractionDigits={1}
              prefix="% "
              locale="es-AR"
            />
          </div>
        </div>
        <div className="flex align-items-center gap-2 mb-3">
          <RadioButton
            inputId="customPrice"
            name="priceOption"
            value="customPrice"
            onChange={(e) => setSelectedOption(e.value)}
            checked={selectedOption === "customPrice"}
          />
          <label htmlFor="customPrice">{t("quoter.editFinalPrice")}: </label>
        </div>
        <div className="flex align-items-center gap-2 mt-3">
          <label
            htmlFor="finalPrice"
            style={{
              minWidth: "100px",
              marginRight: "3px",
              fontWeight: "bold",
            }}
          >
            {t("quoter.finalRepairCost")}:
          </label>
          <InputNumber
            inputId="finalPrice"
            value={values.finalCost}
            onChange={(e) => handleFinalPriceChange(e)}
            disabled={selectedOption === "margin"}
            mode="currency"
            currency="ARS"
            locale="es-AR"
          />
        </div>
      </Fieldset>
    </div>
  );
};

export default RepairCostsComponent;
