import { useFormik } from "formik";
import { t } from "i18next";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { selectAuth } from "../../../app/auth/authSlice";
import { useAppDispatch } from "../../../app/hooks";
import { fetchUpdateUserInfo, fetchUserInfo } from "../userInfoSlice";
interface ConfirmDialogProps {
  visible: boolean;
  onHide: () => void;
  onConfirm: () => void;
}
const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  visible,
  onHide,
  onConfirm,
}) => (
  <Dialog
    visible={visible}
    style={{ width: "450px" }}
    header={t("Confirmation")}
    modal
    footer={() => (
      <div>
        <Button
          label={t("No")}
          icon="pi pi-times"
          onClick={onHide}
          className="p-button-text"
        />
        <Button
          label={t("Yes")}
          icon="pi pi-check"
          onClick={onConfirm}
          autoFocus
        />
      </div>
    )}
    onHide={onHide}
  >
    <div className="confirmation-content">
      <i
        className="pi pi-exclamation-triangle mr-3"
        style={{ fontSize: "2rem" }}
      />
      {<span>{t("userInfo.confirmDialog")}</span>}
    </div>
  </Dialog>
);

const handleGoBack = () => {
  window.history.back();
};

const UserInfo: React.FC = ({}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const userState = useSelector(selectAuth).user;
  const [displayConfirmationDialog, setDisplayConfirmationDialog] =
    useState(false);

  const validationSchema = Yup.object({
    company: Yup.string().required(t("userInfo.companyNameRequired")),
    margin: Yup.number()
      .typeError(t("userInfo.marginNumber"))
      .required(t("userInfo.marginRequired")),
  });

  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [dispatch]);

  const handleConfirmUpdate = () => {
    if (userState && userState.id) {
      const updateData = {
        ...userState,
        company: formik.values.company,
        margin: formik.values.margin,
      };
      dispatch(fetchUpdateUserInfo(updateData)).then((action) => {
        if (fetchUpdateUserInfo.fulfilled.match(action)) {
          toast.success(t("userInfo.updateInfoSuccess"));
        }
      });
      setDisplayConfirmationDialog(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      company: "",
      margin: 0,
    },
    validationSchema,
    onSubmit: (values) => {
      setDisplayConfirmationDialog(true);
    },
  });

  useEffect(() => {
    if (userState) {
      formik.setFieldValue("company", userState.company);
      formik.setFieldValue("margin", userState.margin);
    }
  }, [userState]);

  const handleMarginChange = (e: any) => {
    const newMargin = parseFloat(e.value) || 0;
    formik.setFieldValue("margin", newMargin);
  };

  return (
    <div className="p-fluid">
      <form onSubmit={formik.handleSubmit}>
        <div className="flex justify-content-between mb-4">
          <h3 className="mb-4">{t("userInfo.title")}</h3>
          <Button
            icon="pi pi-arrow-left"
            onClick={() => handleGoBack()}
            type="button"
            className="ml-5 h-3rem"
          />
        </div>

        <div className="flex flex-column gap-1 mb-3">
          <label htmlFor="company">{t("userInfo.companyName")}</label>
          <InputText
            id="company"
            name="company"
            onChange={formik.handleChange}
            value={formik.values.company}
            className={
              formik.errors.company && formik.touched.company ? "p-invalid" : ""
            }
          />
          {formik.touched.company && formik.errors.company ? (
            <small className="p-error">{formik.errors.company}</small>
          ) : null}
        </div>

        <div className="flex flex-column gap-1 mb-3">
          <div
            className="flex items-center justify-between"
            style={{ alignItems: "center" }}
          >
            <label htmlFor="companyEmail" style={{ lineHeight: "1.5rem" }}>
              {t("userInfo.companyEmail")}
            </label>
            <Tooltip target=".custom-target-icon" />
            <i
              className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
              data-pr-tooltip={t("userInfo.companyEmailTooltip")}
              data-pr-position="right"
              data-pr-at="right+5 top"
              data-pr-my="left center-2"
              style={{
                fontSize: "1rem",
                cursor: "pointer",
                marginLeft: "8px",
                alignSelf: "center",
              }}
            ></i>
          </div>
          <InputText
            id="companyEmail"
            name="companyEmail"
            value={userState?.email || ""}
            disabled
          />
        </div>

        <div className="flex flex-column gap-1 mb-3">
          <label htmlFor="margin">{t("userInfo.marginToCalculateCost")}</label>
          <InputNumber
            inputId="marginValue"
            value={formik.values.margin}
            onChange={(e) => handleMarginChange(e)}
            mode="decimal"
            minFractionDigits={1}
            prefix="% "
            className={
              formik.errors.margin && formik.touched.margin ? "p-invalid" : ""
            }
          />
          {formik.touched.margin && formik.errors.margin ? (
            <small className="p-error">{formik.errors.margin}</small>
          ) : null}
        </div>

        <Button
          type="submit"
          label={t("userInfo.saveChanges")}
          disabled={!formik.values.company || !formik.values.margin}
        />
      </form>
      <ConfirmDialog
        visible={displayConfirmationDialog}
        onHide={() => setDisplayConfirmationDialog(false)}
        onConfirm={handleConfirmUpdate}
      />
    </div>
  );
};
export default UserInfo;
