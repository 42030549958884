import { Form, Formik, FormikProps } from "formik";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Divider } from "primereact/divider";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks";
import { FormValuesModel } from "../model/quoterModel";
import { generateOrder, selectQuoterState } from "../quoterSlice";
import { getValidationSchema } from "../utils/validationSchema";
import ClientForm from "./clientForm";
import RepairForm from "./repairForm";

const GENERATE_ORDER = 3;
const GENERATE_QUOTATION = 1;

const Quoter: React.FC = () => {
  const urlParams = useParams();
  const navigate = useNavigate();
  const formikRef = useRef<FormikProps<FormValuesModel>>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const { orderStatus, orderError } = useSelector(selectQuoterState);
  const {
    selectedDeviceType,
    selectedManufacturer,
    selectedDevice,
    selectedRepairType,
  } = useSelector(selectQuoterState);

  const isSubmitDisabled =
    !selectedDeviceType ||
    !selectedManufacturer ||
    !selectedDevice ||
    !selectedRepairType;

  const [shouldSubmitAfterConfirmation, setShouldSubmitAfterConfirmation] =
    useState(false);

  const handleConfirmAccept = () => {
    setVisible(false);
    if (shouldSubmitAfterConfirmation) {
      setShouldSubmitAfterConfirmation(false);
      const values = formikRef.current?.values;
      if (values) {
        dispatch(generateOrder({ orderData: values })).then(() => {
          const url = urlParams?.from || "/";
          navigate(url);
        });
      }
    }
  };

  useEffect(() => {
    const orderType = formikRef.current?.values.orderStatus;

    if (orderType == GENERATE_ORDER && orderStatus === "succeeded") {
      toast.success(t("Order submitted successfully"));
    } else if (orderType == GENERATE_QUOTATION && orderStatus === "succeeded") {
      toast.success(t("Quotation submitted successfully"));
    }
  }, [orderStatus, orderError]);

  const handleConfirmReject = () => {
    setVisible(false);
    setShouldSubmitAfterConfirmation(false);
  };

  const handleSubmitClick = () => {
    if (formikRef.current) {
      const userEmail = formikRef.current.values.clientEmail;
      if (!userEmail) {
        setShouldSubmitAfterConfirmation(true);
        setVisible(true);
      } else {
        // if clientMail is present, dispatch the submit
        formikRef.current.handleSubmit();
      }
    }
  };

  return (
    <div className="mb-6">
      <ConfirmDialog
        visible={visible}
        onHide={handleConfirmReject}
        message={t("validation.confirmationWithoutEmail")}
        header={t("Confirmation")}
        icon="pi pi-exclamation-triangle"
        acceptClassName="p-button-danger"
        acceptLabel={t("Yes")}
        rejectLabel={t("No")}
        accept={handleConfirmAccept}
        reject={handleConfirmReject}
      />
      <Formik
        initialValues={{
          deviceType: null,
          manufacturer: null,
          device: null,
          repairType: null,
          repairSubtype: null,
          repairCost: null,
          serialNumber: null,
          observations: null,
          finalCost: null,
          clientName: null,
          clientEmail: null,
          documentNumber: null,
          phoneNumber: null,
          street: null,
          streetNumber: null,
          location: null,
          postalCode: null,
          floor: null,
          department: null,
          orderStatus: null,
        }}
        validationSchema={getValidationSchema(t)}
        onSubmit={(values, { setSubmitting }) => {
          if (!shouldSubmitAfterConfirmation) {
            dispatch(generateOrder({ orderData: values })).then(() => {
              setSubmitting(false);
              const url = urlParams?.from || "/";
              navigate(url);
            });
          }
        }}
        validateOnMount
        innerRef={formikRef}
      >
        {({ handleSubmit, isValid, isSubmitting, errors }) => (
          <Form onSubmit={handleSubmit}>
            <RepairForm />
            <ClientForm />

            <Divider />

            <div className="p-fluid">
              <Button
                label={t("quoter.sendQuotation")}
                type="button"
                onClick={() => {
                  formikRef.current.setFieldValue(
                    "orderStatus",
                    GENERATE_QUOTATION,
                  );
                  handleSubmitClick();
                }}
                disabled={isSubmitDisabled || isSubmitting || !isValid}
                className="mb-2"
              />
              <Button
                label={t("quoter.generateOrder")}
                type="button"
                onClick={() => {
                  formikRef.current.setFieldValue(
                    "orderStatus",
                    GENERATE_ORDER,
                  );
                  handleSubmitClick();
                }}
                disabled={isSubmitDisabled || isSubmitting || !isValid}
                className="p-button-secondary"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Quoter;
