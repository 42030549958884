import React from "react";
import { useAppSelector } from "../hooks";
import { selectApp } from "../redux/appSlice";

export function FullPageLoader() {
  return (
    <div className="loader-backdrop">
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    </div>
  );
}

export function Loader() {
  const { loading } = useAppSelector(selectApp);
  return <div>{loading && <FullPageLoader />}</div>;
}
