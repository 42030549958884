import React from "react";
import { Route } from "react-router-dom";
import RoutesDefinitions from "../../routes-definition";
import OrderDetail from "./components/orderDetail";
import OrdersComponent from "./components/orders";

export default (
  <Route path={RoutesDefinitions.order.orders} key="orders">
    <Route index element={<OrdersComponent />} key="orders" />
    <Route
      path={RoutesDefinitions.order.orderDetail}
      element={<OrderDetail />}
      key="orderDetails"
    />
  </Route>
);
