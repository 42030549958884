import {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { setLoading } from "../redux/appSlice";
import { store } from "../redux/store";

let promisesCount = 0;

const start = (config: InternalAxiosRequestConfig) => {
  if (config.headers.isAutocompleteRequest) {
    return;
  }
  if (promisesCount === 0) {
    store.dispatch(setLoading(true));
  }
  promisesCount += 1;
};

const end = (config: InternalAxiosRequestConfig) => {
  if (config.headers.isAutocompleteRequest) {
    return;
  }
  promisesCount -= 1;
  if (promisesCount === 0) {
    store.dispatch(setLoading(false));
  }
};

const onRequest = (
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig => {
  start(config);
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  if (error.config) {
    end(error.config);
  }
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  end(response.config);
  return response;
};

const onResponseError = async (error: AxiosError): Promise<unknown> => {
  if (error.config) {
    end(error.config);
  }
  return Promise.reject(error);
};

export function setupLoadingInterceptorTo(
  axiosInstance: AxiosInstance,
): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}
