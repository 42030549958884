import { t } from "i18next";
import { Accordion, AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { useIsMobile } from "../../../utils/responsiveDesign";
import { deviceModel } from "../../quoter/model/quoterModel";
import {
  fetchDeviceTypes,
  fetchManufacturers,
  selectQuoterState,
  setSelectedDeviceType,
  setSelectedManufacturer
} from "../../quoter/quoterSlice";
import {  useSelector } from "react-redux";
import { useAppDispatch } from "../../../app/hooks";

interface OrdersSearchPanelProps {
  searchId: string;
  setSearchId: (value: string) => void;
  searchDocumentNumber: string | undefined;
  setSearchDocumentNumber: (value: string) => void;
  searchDevice: deviceModel | null;
  setSearchDevice: (value: deviceModel) => void;
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  dateRange: Date[] | null;
  setDateRange: (value: Date[] | null) => void;
  handleSearch: () => void;
  filteredDevices: { label: string; value: string };
}

const OrdersSearchPanel: React.FC<OrdersSearchPanelProps> = ({
  searchId,
  setSearchId,
  searchDocumentNumber,
  setSearchDocumentNumber,
  searchDevice,
  setSearchDevice,
  searchTerm,
  setSearchTerm,
  dateRange,
  setDateRange,
  handleSearch,
  filteredDevices,
}) => {
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const devicesTypesArray = useSelector(selectQuoterState).deviceTypes;
  const manufacturers = useSelector(selectQuoterState).manufacturers;

  const { selectedDeviceType, selectedManufacturer } = useSelector(selectQuoterState);

  const [filteredDeviceTypes, setFilteredDeviceTypes] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [filteredManufacturers, setFilteredManufacturers] = useState<
    Array<{ label: string; value: string }>
  >([]);

  useEffect(() => {
    dispatch(fetchDeviceTypes());
  }, [dispatch]);

  useEffect(() => {
    if (devicesTypesArray && devicesTypesArray.length > 0) {
      const formattedDeviceTypes: Array<{ label: string; value: string }> =
        devicesTypesArray.map((deviceType) => ({
          label: deviceType.attributes.description,
          value: deviceType.id,
        }));
      setFilteredDeviceTypes(formattedDeviceTypes);
    }
  }, [devicesTypesArray]);

  useEffect(() => {
    if (manufacturers && manufacturers.length > 0) {
      const formattedManufacturers = manufacturers.map((manufacturer) => ({
        label: manufacturer.attributes.name,
        value: manufacturer.id,
      }));
      setFilteredManufacturers(formattedManufacturers);
    }
  }, [manufacturers]);

  const renderSearchFields = () => (
    <>
      <div className={isMobile ? "input-mobile" : "input-desktop"}>
        <InputText
          value={searchId}
          onChange={(e) => setSearchId(e.target.value)}
          placeholder={t("order.orderNumber")}
        />
      </div>
      <div className={isMobile ? "input-mobile" : "input-desktop"}>
        <InputMask
          value={searchDocumentNumber}
          onChange={(e) => setSearchDocumentNumber(e.value)}
          placeholder={t("order.clientDocumentNumber")}
          mask="99.999.999"
        />
      </div>
      <div className={isMobile ? "input-mobile" : "input-desktop"}>
        <AutoComplete
          id="deviceModel"
          value={searchDevice}
          suggestions={filteredDevices}
          completeMethod={(e) => setSearchTerm(e.query)}
          field="label"
          dropdown
          onChange={(e) => {
            setSearchDevice(e.value);
          }}
          placeholder={t("order.technicalName")}
        />
      </div>
      <div className={isMobile ? "input-mobile" : "input-desktop"}>
        <AutoComplete
          id="deviceType"
          value={selectedDeviceType}
          suggestions={filteredDeviceTypes}
          completeMethod={(e) => {
            const query = e.query.toLowerCase();
            setFilteredDeviceTypes(
              devicesTypesArray
                .map((dt) => ({
                  label: dt.attributes.description,
                  value: dt.id,
                }))
                .filter((item) => item.label.toLowerCase().includes(query)),
            );
          }}
          field="label"
          dropdown
          onChange={(e) => {
            if (e.value) {
              dispatch(setSelectedDeviceType(e.value));
              dispatch(setSelectedManufacturer(null));
              dispatch(fetchManufacturers(e.value.value));
            } else {
              dispatch(setSelectedDeviceType(null));
              dispatch(setSelectedManufacturer(null));
            }
          }}
          placeholder={t("quoter.deviceType")}
        />
      </div>
      <div className={isMobile ? "input-mobile" : "input-desktop"}>
        <AutoComplete
          id="manufacturer"
          value={selectedManufacturer}
          suggestions={filteredManufacturers}
          disabled={!selectedDeviceType}
          completeMethod={(e) => {
            const query = e.query.toLowerCase();
            setFilteredManufacturers(
              manufacturers
                .map((manufacturer) => ({
                  label: manufacturer.attributes.name,
                  value: manufacturer.id,
                }))
                .filter((manufacturer) =>
                  manufacturer.label.toLowerCase().includes(query),
                ),
            );
          }}
          field="label"
          dropdown
          onChange={(e) => {
            dispatch(setSelectedManufacturer(e.value));
          }}
          placeholder={t("quoter.manufacturer")}
        />
      </div>
      <div className={isMobile ? "input-mobile" : "input-desktop"}>
        <Calendar
          value={dateRange}
          onChange={(e) => setDateRange(e.value)}
          placeholder={t("order.selectDateRange")}
          selectionMode="range"
          readOnlyInput
          showButtonBar
        />
      </div>
      <div className={isMobile ? "input-mobile" : "input-desktop"}>
        <Button
          icon="pi pi-search"
          onClick={handleSearch}
          className={isMobile ? "button-mobile" : "button-desktop"}
        />
      </div>
    </>
  );
  return (
    <>
      {isMobile ? (
        <Accordion className="mb-3">
          <AccordionTab header={t("order.searchOrder")}>
            <div className="flex flex-column gap-3">{renderSearchFields()}</div>
          </AccordionTab>
        </Accordion>
      ) : (
        <div className="flex flex-wrap gap-3 justify-content-start mb-3">
          {renderSearchFields()}
        </div>
      )}
    </>
  );
};

export default OrdersSearchPanel;
