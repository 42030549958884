import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { fetchUserInfo } from "../../../features/userInfo/userInfoSlice";
import { getUserRedirect } from "../../../utils/userRedirection";
import { useAppDispatch, useAppSelector } from "../../hooks";
import MainLayout from "../../layouts/MainLayout";
import { selectAuth } from "../authSlice";
import { PermissionsContext } from "../contexts/permissionsContext";
import { ability } from "../lib/permissionsService";

export function RequireAuth() {
  const { tokens, user } = useAppSelector(selectAuth);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (tokens && !user) {
      dispatch(fetchUserInfo());
    }

    if (user && user.role) {
      const redirectTo = getUserRedirect(user.role);
      if (location.pathname === "/") {
        navigate(redirectTo);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate, tokens, user]);

  if (!tokens) {
    return <Navigate to="/auth/signin" state={{ from: location }} />;
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {user && (
        <PermissionsContext.Provider value={ability}>
          <MainLayout>
            <Outlet />
          </MainLayout>
        </PermissionsContext.Provider>
      )}
    </>
  );
}
