import RoutesDefinitions from "../routes-definition";
export type UserRole = "Technic Admin" | "Technic User";

const defaultRedirect = RoutesDefinitions.order.orders;

export function getUserRedirect(role: UserRole): string {
  return role === "Technic Admin" || role === "Technic User"
    ? defaultRedirect
    : defaultRedirect;
}
