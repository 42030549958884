import { useFormikContext } from "formik";
import { t } from "i18next";
import { InputText } from "primereact/inputtext";
import React from "react";
import { FormValuesModel } from "../model/quoterModel";

const ClientForm: React.FC = () => {
  const { values, handleChange, touched, handleBlur, errors } =
    useFormikContext<FormValuesModel>();

  return (
    <div className="p-fluid">
      <h3>{t("quoter.clientInformation")}</h3>

      <div className="flex flex-column gap-1 mb-3">
        <InputText
          id="clientName"
          name="clientName"
          onChange={handleChange}
          value={values.clientName ?? ""}
          placeholder={t("userInfo.clientName")}
        />
      </div>

      <div className="flex flex-column gap-1 mb-3">
        <div className="p-inputgroup flex-1">
          <span className="p-inputgroup-addon">
            <i className="pi pi-envelope"></i>
          </span>
          <InputText
            id="clientEmail"
            name="clientEmail"
            onChange={handleChange}
            value={values.clientEmail ?? ""}
            onBlur={handleBlur}
            className={
              touched.clientEmail && errors.clientEmail ? "p-invalid" : ""
            }
            placeholder={t("userInfo.clientEmail")}
          />
        </div>
        {touched.clientEmail && errors.clientEmail && (
          <small className="p-error">{errors.clientEmail}</small>
        )}
      </div>

      <div className="flex flex-column gap-1 mb-3">
        <div className="p-inputgroup flex-1">
          <span className="p-inputgroup-addon">
            <i className="pi pi-phone"></i>
          </span>
          <InputText
            id="phoneNumber"
            name="phoneNumber"
            onChange={handleChange}
            value={values.phoneNumber ?? ""}
            onBlur={handleBlur}
            className={
              touched.phoneNumber && errors.phoneNumber ? "p-invalid" : ""
            }
            placeholder={t("userInfo.phoneNumber")}
          />
        </div>
        {touched.phoneNumber && errors.phoneNumber && (
          <small className="p-error">{errors.phoneNumber}</small>
        )}
      </div>

      <div className="flex flex-column gap-1 mb-3">
        <InputText
          id="documentNumber"
          name="documentNumber"
          onChange={handleChange}
          value={values.documentNumber ?? ""}
          placeholder={t("userInfo.documentNumber")}
        />
      </div>

      <div className="flex flex-column gap-1 mb-3">
        <InputText
          id="street"
          name="street"
          onChange={handleChange}
          value={values.street ?? ""}
          placeholder={t("userInfo.street")}
        />
      </div>

      <div className="flex flex-column gap-1 mb-3">
        <InputText
          id="streetNumber"
          name="streetNumber"
          onChange={handleChange}
          value={values.streetNumber ?? ""}
          placeholder={t("userInfo.streetNumber")}
        />
      </div>

      <div className="flex flex-column gap-1 mb-3">
        <InputText
          id="location"
          name="location"
          onChange={handleChange}
          value={values.location ?? ""}
          placeholder={t("userInfo.location")}
        />
      </div>

      <div className="flex flex-column gap-1 mb-3">
        <InputText
          id="postalCode"
          name="postalCode"
          onChange={handleChange}
          value={values.postalCode ?? ""}
          placeholder={t("userInfo.postalCode")}
        />
      </div>

      <div className="flex flex-column gap-1 mb-3">
        <InputText
          id="floor"
          name="floor"
          onChange={handleChange}
          value={values.floor ?? ""}
          placeholder={t("userInfo.floor")}
        />
      </div>

      <div className="flex flex-column gap-1 mb-3">
        <InputText
          id="department"
          name="department"
          onChange={handleChange}
          value={values.department ?? ""}
          placeholder={t("userInfo.department")}
        />
      </div>
    </div>
  );
};

export default ClientForm;
