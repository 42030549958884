import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./store";

interface AppState {
  loading: boolean;
}

const initialState: AppState = {
  loading: false,
};

const appSlice = createSlice({
  name: "app",

  initialState,

  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const { setLoading } = appSlice.actions;
export const selectApp = (state: RootState) => state.app;
export const appReducer = appSlice.reducer;
