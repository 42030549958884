import * as Sentry from "@sentry/react";
import axios from "axios";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { setupAuthInterceptorTo } from "./app/auth/interceptors/axiosAuthInterceptor";
import { FullPageLoader, Loader } from "./app/components/Loader";
import "./app/i18n";
import { setupErrorInterceptorTo } from "./app/interceptors/axiosErrorInterceptor";
import { setupLoadingInterceptorTo } from "./app/interceptors/axiosLoadingInterceptor";
import { store } from "./app/redux/store";
import "./css/style.css";

// Setup Axios interceptors
axios.defaults.baseURL = import.meta.env.VITE_REACT_APP_BACKEND_URL || "/api";
setupAuthInterceptorTo(axios);
setupErrorInterceptorTo(axios);
setupLoadingInterceptorTo(axios);

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  // <React.StrictMode>
  <Provider store={store}>
    <React.Suspense fallback={<FullPageLoader />}>
      <Toaster />
      <Loader />
      <Router>
        <App />
      </Router>
    </React.Suspense>
  </Provider>,
  // </React.StrictMode>,
);
