import "primereact/resources/themes/lara-light-cyan/theme.css";

import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { LoginRedirect } from "./app/auth/components/signin/LoginRedirect";
import { SignIn } from "./app/auth/components/signin/SignIn";
import { RequireAuth } from "./app/auth/providers/authProvider";
import features from "./features";

const App: React.FC = () => {
  useEffect(() => {
    const html = document.querySelector("html");
    if (html) {
      html.style.scrollBehavior = "auto";
      window.scroll({ top: 0 });
      html.style.scrollBehavior = "";
    }
  }, [location.pathname]);

  if (!import.meta.env.VITE_REACT_APP_BACKEND_URL) {
    return (
      <p>
        Please specify your backend url with the{" "}
        <a
          href="https://create-react-app.dev/docs/adding-custom-environment-variables/"
          target="_blank"
          rel="noopener noreferrer"
        >
          environment variable
        </a>
        :<br />
        <b>REACT_APP_BACKEND_URL</b>.<br />
      </p>
    );
  }

  return (
    <Routes>
      <Route path="/auth/signin" element={<SignIn />} />
      <Route path="/connect/google/redirect" element={<LoginRedirect />} />
      <Route path="/" element={<RequireAuth />}>
        {features.map((route) => ({ ...route }))}
      </Route>
    </Routes>
  );
};

export default App;
