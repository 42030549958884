import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  doGetDevices,
  doGetDeviceTypes,
  doGetManufacturers,
  doGetRepairCosts,
  doGetRepairSubtypes,
  doGetRepairTypes,
  doPostGenerateOrder,
} from "./api/quoterApis";
import {
  deviceModel,
  deviceTypeModel,
  manufacturerModel,
  repairCostModel,
  repairSubtypesModel,
  repairTypeModel,
  FormValuesModel,
} from "./model/quoterModel";

interface QuoterState {
  devices: deviceModel[] | null;
  deviceTypes: deviceTypeModel[] | null;
  manufacturers: manufacturerModel[] | null;
  repairTypes: repairTypeModel[] | null;
  repairSubtypes: repairSubtypesModel[] | null;
  repairCost: repairCostModel | null;
  selectedDeviceType: string;
  selectedManufacturer: string;
  selectedDevice: string;
  selectedRepairType: string;
  selectedRepairSubtype: string;
  isLoadingSubtypes: boolean;
  orderStatus: string;
  orderError: string | undefined;
}

const initialState: QuoterState = {
  devices: null,
  deviceTypes: null,
  manufacturers: null,
  repairTypes: null,
  repairSubtypes: null,
  repairCost: null,
  selectedDeviceType: "",
  selectedManufacturer: "",
  selectedDevice: "",
  selectedRepairType: "",
  selectedRepairSubtype: "",
  isLoadingSubtypes: false,
  orderStatus: "idle",
  orderError: undefined,
};

export const fetchDeviceTypes = createAsyncThunk("getDeviceTypes", async () =>
  doGetDeviceTypes(),
);

export const fetchManufacturers = createAsyncThunk(
  "getManufacturers",
  async (deviceTypeId: string) => doGetManufacturers(deviceTypeId),
);

export const fetchDevices = createAsyncThunk(
  "quoter/getDevices",
  async ({
    manufacturerId,
    deviceTypeId,
    searchTerm,
  }: {
    manufacturerId: string;
    deviceTypeId: string;
    searchTerm: string;
  }) => {
    return await doGetDevices(manufacturerId, deviceTypeId, searchTerm);
  },
);

export const fetchRepairTypes = createAsyncThunk(
  "getRepairTypes",
  async (deviceTypeId: string) => doGetRepairTypes(deviceTypeId),
);

export const fetchRepairCosts = createAsyncThunk(
  "getRepairCosts",
  async ({
    repairTypeId,
    deviceId,
    repairSubtypeId,
  }: {
    repairTypeId: string;
    deviceId: string;
    repairSubtypeId?: string;
  }) => {
    return await doGetRepairCosts(repairTypeId, deviceId, repairSubtypeId);
  },
);

export const fetchRepairSubtypes = createAsyncThunk(
  "getRepairSubtypes",
  async ({
    deviceId,
    repairTypeId,
  }: {
    deviceId: string;
    repairTypeId: string;
  }) => {
    return await doGetRepairSubtypes(deviceId, repairTypeId);
  },
);

export const generateOrder = createAsyncThunk(
  "quoter/generateOrder",
  async ({ orderData }: { orderData: FormValuesModel }) => {
    return await doPostGenerateOrder(orderData);
  },
);

const quoterSlice = createSlice({
  name: "quoter",
  initialState,
  reducers: {
    setSelectedDeviceType: (state, action: PayloadAction<string>) => {
      state.selectedDeviceType = action.payload;
    },
    setSelectedManufacturer: (state, action: PayloadAction<string>) => {
      state.selectedManufacturer = action.payload;
    },
    setSelectedDevice: (state, action: PayloadAction<string>) => {
      state.selectedDevice = action.payload;
    },
    setSelectedRepairType: (state, action: PayloadAction<string>) => {
      state.selectedRepairType = action.payload;
    },
    setSelectedRepairSubtype: (state, action: PayloadAction<string>) => {
      state.selectedRepairSubtype = action.payload;
    },
    clearRepairSubtypes: (state) => {
      state.repairSubtypes = null;
    },
    clearRepairCost: (state) => {
      state.repairCost = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateOrder.fulfilled, (state, action) => {
      state.orderStatus = "succeeded";
    });
    builder.addCase(generateOrder.rejected, (state, action) => {
      state.orderStatus = "failed";
      state.orderError = action.error.message;
    });
    builder.addCase(fetchDeviceTypes.fulfilled, (state, action) => {
      state.deviceTypes = action.payload;
    });
    builder.addCase(fetchManufacturers.fulfilled, (state, action) => {
      state.manufacturers = action.payload;
    });
    builder.addCase(fetchDevices.fulfilled, (state, action) => {
      state.devices = action.payload;
    });
    builder.addCase(fetchRepairTypes.fulfilled, (state, action) => {
      state.repairTypes = action.payload;
    });
    builder.addCase(fetchRepairSubtypes.pending, (state) => {
      state.isLoadingSubtypes = true;
    });
    builder.addCase(fetchRepairSubtypes.fulfilled, (state, action) => {
      state.repairSubtypes = action.payload;
      state.isLoadingSubtypes = false;
    });
    builder.addCase(fetchRepairSubtypes.rejected, (state) => {
      state.isLoadingSubtypes = false;
    });
    builder.addCase(fetchRepairCosts.fulfilled, (state, action) => {
      state.repairCost = action.payload;
    });
    builder.addCase(generateOrder.pending, (state) => {
      state.orderStatus = "loading";
    });
  },
});

export const {
  setSelectedDeviceType,
  setSelectedManufacturer,
  setSelectedDevice,
  setSelectedRepairType,
  setSelectedRepairSubtype,
  clearRepairCost,
  clearRepairSubtypes,
} = quoterSlice.actions;

export const selectQuoterState = (state: { quoter: QuoterState }) =>
  state.quoter;

export default quoterSlice.reducer;
