import axios, { AxiosError } from "axios";
import { AuthUser } from "../../../app/auth/models/auth-user";

export async function doGetUserInfo() {
  const response = await axios.get("/api/users/me?populate=role");
  const formattedResponse = {
    ...response.data,
    role: response.data.role.name,
  };

  return formattedResponse;
}

export async function doUpdateUserInfo(userData: AuthUser) {
  try {
    const { role, ...userDataWithoutRole } = userData;
    const response = await axios.put<any>(
      `/api/users/${userData.id}`,
      userDataWithoutRole,
    );
    return response.data;
  } catch (err: unknown | AxiosError) {
    throw err;
  }
}
