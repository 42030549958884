import { useFormikContext } from "formik";
import { t } from "i18next";
import { AutoComplete } from "primereact/autocomplete";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../app/hooks";
import CustomAutoComplete from "../../../partials/components/CustomAutocomplete";
import { FormValuesModel } from "../model/quoterModel";
import {
  clearRepairCost,
  clearRepairSubtypes,
  fetchDeviceTypes,
  fetchDevices,
  fetchManufacturers,
  fetchRepairSubtypes,
  fetchRepairTypes,
  selectQuoterState,
  setSelectedDevice,
  setSelectedDeviceType,
  setSelectedManufacturer,
  setSelectedRepairType,
} from "../quoterSlice";
import RepairCostsComponent from "./RepairCostsComponent";
import { InputText } from "primereact/inputtext";

const RepairForm = () => {
  const dispatch = useAppDispatch();
  const { errors, touched, setFieldValue, values } =
    useFormikContext<FormValuesModel>();
  const devicesTypesArray = useSelector(selectQuoterState).deviceTypes;
  const manufacturersTypesArray = useSelector(selectQuoterState).manufacturers;
  const devicesArray = useSelector(selectQuoterState).devices;
  const repairTypesArray = useSelector(selectQuoterState).repairTypes;
  const repairSubtypesArray = useSelector(selectQuoterState).repairSubtypes;
  const repairCostData = useSelector(selectQuoterState).repairCost;

  const {
    selectedDeviceType,
    selectedManufacturer,
    selectedDevice,
    selectedRepairType,
    isLoadingSubtypes,
  } = useSelector(selectQuoterState);

  const [subtypeSelected, setSubtypeSelected] = useState(false);
  const [selectedRepairSubtype, setSelectedRepairSubtype] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const searchRef = useRef<NodeJS.Timeout | null>(null);

  const [filteredDeviceTypes, setFilteredDeviceTypes] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [filteredRepairTypes, setRepairTypes] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [manufacturers, setManufacturers] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [filteredDevices, setDevices] = useState<
    Array<{ label: string; value: string }>
  >([]);

  useEffect(() => {
    dispatch(fetchDeviceTypes());
  }, [dispatch]);

  useEffect(() => {
    if (devicesTypesArray && devicesTypesArray.length > 0) {
      const formattedDeviceTypes: Array<{ label: string; value: string }> =
        devicesTypesArray.map((deviceType) => ({
          label: deviceType.attributes.description,
          value: deviceType.id,
        }));
      setFilteredDeviceTypes(formattedDeviceTypes);
    }
  }, [devicesTypesArray]);

  useEffect(() => {
    if (manufacturersTypesArray && manufacturersTypesArray.length > 0) {
      const formattedManufacturers: Array<{ label: string; value: string }> =
        manufacturersTypesArray.map((manufacturer) => ({
          label: manufacturer.attributes.name,
          value: manufacturer.id,
        }));
      setManufacturers(formattedManufacturers);
    }
  }, [manufacturersTypesArray]);

  useEffect(() => {
    if (devicesArray && devicesArray.length > 0) {
      const formattedDevices: Array<{ label: string; value: string }> =
        devicesArray.map((device) => ({
          label: device.attributes.commercialName,
          value: device.id,
        }));
      setDevices(formattedDevices);
    } else {
      setDevices(null);
    }
  }, [devicesArray]);

  useEffect(() => {
    if (repairTypesArray && repairTypesArray.length > 0) {
      const formattedRepairTypes: Array<{ label: string; value: string }> =
        repairTypesArray.map((repairType) => ({
          label: repairType.attributes.repairName,
          value: repairType.id,
        }));
      setRepairTypes(formattedRepairTypes);
    }
  }, [repairTypesArray]);

  useEffect(() => {
    setFieldValue("repairCost", repairCostData);
  }, [repairCostData]);

  const getManufacturers = (deviceTypeId: string) => {
    dispatch(fetchManufacturers(deviceTypeId));
    dispatch(fetchRepairTypes(deviceTypeId));
  };

  useEffect(() => {
    if (values.repairType && selectedDevice && selectedRepairType) {
      dispatch(
        fetchRepairSubtypes({
          deviceId: selectedDevice,
          repairTypeId: selectedRepairType,
        }),
      );
    }
  }, [values.repairType, dispatch]);

  useEffect(() => {
    if (
      searchTerm &&
      values.manufacturer &&
      selectedDeviceType &&
      selectedManufacturer
    ) {
      const manufacturerId = selectedManufacturer;
      const deviceTypeId = selectedDeviceType;
      if (searchRef.current) {
        clearTimeout(searchRef.current);
      }
      searchRef.current = setTimeout(() => {
        dispatch(fetchDevices({ manufacturerId, deviceTypeId, searchTerm }));
      }, 500);
    }
  }, [searchTerm, values.manufacturer, dispatch]);

  const shouldShowRepairCosts =
    !isLoadingSubtypes &&
    ((repairSubtypesArray && repairSubtypesArray.length === 0) ||
      selectedRepairSubtype);

  const handleSubtypeSelection = (e: any) => {
    setFieldValue("repairSubtype", e.value);
    setSubtypeSelected(true);
    setSelectedRepairSubtype(e.value);
  };

  const handleRepairTypeChange = (e: any) => {
    dispatch(setSelectedRepairType(e)),
      dispatch(clearRepairSubtypes()),
      dispatch(clearRepairCost()),
      setSubtypeSelected(false),
      setFieldValue("repairSubtype", ""),
      setSelectedRepairSubtype("");
  };

  const handleFieldChange = (fieldsToReset: any) => {
    fieldsToReset.forEach((field: string) => {
      setFieldValue(field, "");
    });
  };

  return (
    <div className="p-fluid">
      <h3 className="mb-4">{t("quoter.repairInformation")}</h3>
      <div className="flex flex-column mb-5">
        <span className="p-float-label">
          <CustomAutoComplete
            name="deviceType"
            data={filteredDeviceTypes}
            disabled={false}
            fetchFunction={getManufacturers}
            onSelectionChange={(value) => {
              dispatch(setSelectedDeviceType(value));
              handleFieldChange([
                "manufacturer",
                "device",
                "repairType",
                "repairSubtype",
              ]);
            }}
          />
          <label htmlFor="device">{t("quoter.deviceType")}</label>
        </span>
      </div>

      <div className="flex flex-column gap-1 mb-5">
        <span className="p-float-label">
          <CustomAutoComplete
            name="manufacturer"
            data={manufacturers}
            disabled={!selectedDeviceType}
            onSelectionChange={(value) => {
              dispatch(setSelectedManufacturer(value));
              handleFieldChange(["device", "repairType", "repairSubtype"]);
            }}
          />
          <label htmlFor="manufacturer">{t("quoter.manufacturer")}</label>
        </span>
      </div>

      <div className="flex flex-column gap-1 mb-5">
        <span className="p-float-label">
          <AutoComplete
            id="deviceModel"
            value={values.device}
            suggestions={filteredDevices}
            completeMethod={(e) => setSearchTerm(e.query)}
            field="label"
            onChange={(e) => {
              setFieldValue("device", e.value);
              dispatch(setSelectedDevice(e.value.value));
              handleFieldChange(["repairType", "repairSubtype"]);
            }}
            disabled={!selectedManufacturer}
          />
          <label htmlFor="device">{t("quoter.enterDeviceModel")}</label>
        </span>
      </div>

      <div className="flex flex-column gap-1 mb-5">
        <span className="p-float-label">
          <CustomAutoComplete
            key={selectedDevice || "default-key"}
            name="repairType"
            data={filteredRepairTypes}
            disabled={!values.device}
            onSelectionChange={(e) => {
              handleRepairTypeChange(e);
            }}
          />
          <label htmlFor="repairType">{t("quoter.repairType")}</label>
        </span>
      </div>

      {selectedDevice &&
        selectedRepairType &&
        repairSubtypesArray &&
        repairSubtypesArray.length > 0 && (
          <div className="flex flex-column gap-1 mb-5">
            <span className="p-float-label">
              <Dropdown
                value={values.repairSubtype}
                options={repairSubtypesArray.map((subtype) => ({
                  label: subtype.attributes.name,
                  value: subtype.id,
                }))}
                onChange={(e) => {
                  handleSubtypeSelection(e);
                }}
              />
              <label htmlFor="repairType">{t("quoter.repairSubtype")}</label>
            </span>
          </div>
        )}

      {shouldShowRepairCosts && (
        <RepairCostsComponent
          repairTypeId={selectedRepairType}
          repairSubtypeId={
            selectedRepairSubtype ? selectedRepairSubtype : undefined
          }
          deviceId={selectedDevice}
        />
      )}

      <div className="p-field mt-5">
        <div className="p-inputgroup flex-1">
          <span className="p-float-label">
            <InputText
              id="serialNumber"
              name="serialNumber"
              onChange={(e) => setFieldValue("serialNumber", e.target.value)}
              value={values.serialNumber ?? ""}
              className={
                touched.serialNumber && errors.serialNumber ? "p-invalid" : ""
              }
            />
            <label htmlFor="serialNumber">{t("quoter.serialNumber")}</label>
          </span>
        </div>
        {touched.serialNumber && errors.serialNumber && (
          <small className="p-error">{errors.serialNumber}</small>
        )}
      </div>

      <div className="p-field mt-5">
        <div className="p-inputgroup flex-1">
          <span className="p-float-label">
            <InputTextarea
              id="observations"
              value={values.observations || ""}
              style={{ borderRadius: "4px" }}
              onChange={(e) => setFieldValue("observations", e.target.value)}
              autoResize
              maxLength={2000}
              rows={4}
              cols={30}
            />
            <label htmlFor="observations">{t("quoter.observations")}</label>
          </span>
        </div>
        {touched.observations && errors.observations && (
          <small className="p-error">{errors.observations}</small>
        )}
      </div>
      <Divider />
    </div>
  );
};

export default RepairForm;
