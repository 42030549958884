import * as yup from "yup";

export const getValidationSchema = (t: any) =>
  yup.object({
    observations: yup
      .string()
      .max(500, t("validation.observationsMaxLength"))
      .nullable()
      .notRequired(),
    serialNumber: yup
      .string()
      .max(50, t("validation.serialNumberMaxLength"))
      .nullable()
      .notRequired(),
    clientEmail: yup
      .string()
      .email(t("validation.invalidEmail"))
      .nullable()
      .notRequired(),
    phoneNumber: yup
      .string()
      .matches(/^[0-9]+$/, t("validation.invalidPhone"))
      .nullable()
      .notRequired(),
    street: yup.string().nullable().notRequired(),
    streetNumber: yup.string().nullable().notRequired(),
    location: yup.string().nullable().notRequired(),
    postalCode: yup
      .string()
      .nullable()
      .notRequired(),
    floor: yup
      .string()
      .nullable()
      .notRequired(),
    department: yup
      .string()
      .nullable()
      .notRequired(),
    clientName: yup
      .string()
      .nullable()
      .notRequired(),
    documentNumber: yup
      .string()
      .nullable()
      .notRequired(),
  });
