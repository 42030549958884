import { t } from "i18next";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import React, { ReactNode, useState } from "react";
import { FiMenu } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import RoutesDefinitions from "../../routes-definition";
import { logOut } from "../auth/authSlice";

type MainLayoutProps = {
  children: ReactNode;
};

const styles: Record<string, React.CSSProperties> = {
  ripple: {
    fontSize: "20px",
    fontWeight: "bold",
    borderBottom: "1px solid #ddd",
    color: "grey",
    marginBottom: "20px",
  },
};

function MainLayout({ children }: MainLayoutProps) {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  const handleLogOut = () => {
    dispatch(logOut());
    setVisible(false);
  };

  return (
    <div className="h-full flex flex-column m-0 overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        visible={visible}
        onHide={() => setVisible(false)}
        position="left"
        style={{ width: "280px" }}
        showCloseIcon={false}
      >
        <div className="flex h-full flex-column p-1">
          <Link
            to={RoutesDefinitions.quoter}
            style={{ textDecoration: "none" }}
            onClick={() => setVisible(false)}
          >
            <div style={styles.ripple}>{t("Quoter")}</div>
          </Link>
          <Link
            to={RoutesDefinitions.order.orders}
            style={{ textDecoration: "none" }}
            onClick={() => setVisible(false)}
          >
            <div style={styles.ripple}>{t("Orders")}</div>
          </Link>
          <Link
            to={RoutesDefinitions.userInfo}
            style={{ textDecoration: "none" }}
            onClick={() => setVisible(false)}
          >
            <div style={styles.ripple}>{t("Profile")}</div>
          </Link>

          <Link
            to="#"
            onClick={handleLogOut}
            style={{ textDecoration: "none" }}
          >
            <div style={styles.ripple}>{t("SignOut")}</div>
          </Link>
        </div>
      </Sidebar>

      {/* Content area */}
      <div className="flex w-full p-1">
        <Button
          icon={<FiMenu />}
          onClick={() => setVisible(true)}
          style={styles.buttonStyle}
        />
      </div>
      <div className="flex h-full w-full justify-content-center">
        <div className="w-full xl:w-max overflow-auto p-4 mb-5 scroll-content">
          {children}
        </div>
      </div>
    </div>
  );
}

export default MainLayout;
