import axios from "axios";
import {
  deviceModel,
  FormValuesModel,
  manufacturerModel,
  repairSubtypesModel,
  repairTypeModel,
} from "../model/quoterModel";

const url = import.meta.env.VITE_REACT_APP_BACKEND_URL;

export async function doGetDeviceTypes() {
  const response = await axios.get<any>(`/api/device-types`);
  return response.data.data;
}

export async function doGetManufacturers(deviceTypeId: string) {
  const response = await axios.get<any>(
    `/api/manufacturers?filters\[device_types\][id][$eq]=${deviceTypeId}`,
  );
  const manufacturersData = response.data.data.map(
    (manufacturer: manufacturerModel) => ({
      id: manufacturer.id,
      attributes: {
        name: manufacturer.attributes.name.toString(),
      },
    }),
  );

  return manufacturersData;
}

export async function doGetDevices(
  manufacturerId: string,
  deviceTypeId: string,
  searchTerm: string,
) {
  const response = await axios.get<any>(
    `${url}/api/devices/search?searchTerm=${encodeURIComponent(searchTerm)}&manufacturerId=${manufacturerId}&deviceTypeId=${deviceTypeId}`,
  );
  const devicesData = response.data.data.map((device: deviceModel) => ({
    id: device.id,
    attributes: {
      technicalName: device.attributes.technicalName.toString(),
      commercialName: device.attributes.commercialName.toString(),
    },
  }));

  return devicesData;
}

export async function doGetRepairTypes(deviceTypeId: string) {
  const response = await axios.get<any>(
    `/api/repair-types?filters\[device_types\][id][$eq]=${deviceTypeId}`,
  );

  const repairTypesData = response.data.data.map(
    (repairType: repairTypeModel) => ({
      id: repairType.id,
      attributes: {
        repairName: repairType.attributes.repairName.toString(),
      },
    }),
  );

  return repairTypesData;
}

export async function doGetRepairCosts(
  repairTypeId: string,
  deviceId: string,
  repairSubtypeId?: string,
) {
  let url = `/api/repair-costs?filters[repair_type][id][$eq]=${repairTypeId}&filters[device][id][$eq]=${deviceId}`;

  if (repairSubtypeId) {
    url += `&filters[repair_subtype][id][$eq]=${repairSubtypeId}`;
  }

  const response = await axios.get<any>(url);

  const repairCostData = response.data.data[0]
    ? {
        id: response.data.data[0].id,
        attributes: {
          cost: response.data.data[0].attributes.cost.toString(),
        },
      }
    : null;

  return repairCostData;
}

export async function doGetRepairSubtypes(
  deviceId: string,
  repairTypeId: string,
) {
  const response = await axios.get<any>(
    `/api/repair-subtypes?filters[repair_type][id][$eq]=${repairTypeId}&filters[device][id][$eq]=${deviceId}`,
  );

  const repairSubtypesData = response.data.data.map(
    (subtype: repairSubtypesModel) => ({
      id: subtype.id,
      attributes: {
        name: subtype.attributes.name.toString(),
      },
    }),
  );

  return repairSubtypesData;
}

export async function doPostGenerateOrder(orderData: FormValuesModel) {
  const mappedData = {
    data: {
      clientName: orderData.clientName,
      clientEmail: orderData.clientEmail,
      phoneNumber: orderData.phoneNumber,
      documentNumber: orderData.documentNumber ?? "",
      street: orderData.street,
      streetNumber: orderData.streetNumber,
      location: orderData.location,
      postalCode: orderData.postalCode,
      floor: orderData.floor,
      department: orderData.department,

      device: orderData.device?.value,
      observations: orderData.observations ?? "",
      finalCost: parseFloat(orderData.finalCost) ?? null,
      order_status: orderData.orderStatus,
      repair_costs: orderData.repairCost?.id,
      repairCost: orderData.repairCost?.attributes.cost,
      serialNumber: orderData.serialNumber ?? ""
    },
  };

  const response = await axios.post<any>(`/api/orders`, mappedData);
  return response.data.data;
}
