import React from "react";
import { AutoComplete } from "primereact/autocomplete";
import { useFormikContext } from "formik";

interface CustomAutoCompleteProps {
  name: string;
  placeholder?: string;
  data: Array<{ label: string; value: string }>;
  disabled: boolean;
  fetchFunction?: (value: string) => void;
  filter?: boolean;
  onSelectionChange?: (value: string) => void;
}

const CustomAutoComplete: React.FC<CustomAutoCompleteProps> = ({
  name,
  placeholder,
  data,
  disabled,
  fetchFunction,
  filter = true,
  onSelectionChange,
}) => {
  const { setFieldValue, values } = useFormikContext<any>();
  const [filteredData, setFilteredData] = React.useState<
    Array<{ label: string; value: string }>
  >([]);

  React.useEffect(() => {
    if (filter) {
      setFilteredData(data);
    } else {
      setFilteredData([]);
    }
  }, [data, filter]);

  return (
    <AutoComplete
      id={name}
      value={values[name]}
      suggestions={filteredData}
      completeMethod={(e) => {
        const query = e.query.toLowerCase();
        setFilteredData(
          data.filter((item) => item.label.toLowerCase().includes(query)),
        );
      }}
      field="label"
      dropdown
      onChange={(e) => {
        setFieldValue(name, e.value);
        if (fetchFunction) fetchFunction(e.value.value);
        if (onSelectionChange) onSelectionChange(e.value.value);
      }}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

export default CustomAutoComplete;
