import { Form, Formik } from "formik";
import { t } from "i18next";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { selectAuth } from "../../../app/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { formatPrice } from "../../../utils/general";

import {
  fetchGetOrder,
  fetchGetOrderStatuses,
  selectOrdersState,
  updateOrder,
} from "../ordersSlice";

const OrderDetail = () => {
  const urlParams = useParams();
  const navigate = useNavigate();
  const authState = useAppSelector(selectAuth);
  const isAdmin = authState.user?.role === "Technic Admin";
  const { orderId } = useParams();
  const dispatch = useAppDispatch();
  const { selectedOrder, orderStatus } = useSelector(selectOrdersState);

  useEffect(() => {
    if (orderId) {
      dispatch(fetchGetOrder(orderId));

      // TODO: should dispatch this action only for admin user, for clients, the order status should display the order value
      dispatch(fetchGetOrderStatuses());
    }
  }, [dispatch, orderId]);

  const validationSchema = Yup.object().shape({});

  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <Formik
      initialValues={{
        ...selectedOrder,
        orderStatus: selectedOrder?.order_status.id,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const payload = {
          orderId: selectedOrder?.id,
          orderData: {
            ...selectedOrder,
            order_status: values.orderStatus,
            observations: values.observations,
            finalCost: values.finalCost,
          },
        };
        dispatch(updateOrder(payload)).then(() => {
          setSubmitting(false);
          const url = urlParams?.from || "/";
          navigate(url);
          toast.success(t("Order updated successfully"));
        });
      }}
      enableReinitialize
    >
      {({
        values,
        initialValues,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        errors,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="flex justify-content-between mb-4">
            <h3>
              {t("Order")} {selectedOrder?.id}
            </h3>
            <Button
              icon="pi pi-arrow-left"
              onClick={() => handleGoBack()}
              type="button"
            />
          </div>

          <div className="p-field mt-5">
            <div className="flex flex-column gap-1 mb-3">
              <label htmlFor="createdAt">{t("order.createdAt")}</label>
              <InputText
                id="createdAt"
                name="createdAt"
                value={new Date(values.createdAt).toLocaleDateString()}
                disabled={true}
              />
            </div>

            <div className="flex flex-column gap-1 mb-3">
              <label htmlFor="model">{t("order.model")}</label>
              <InputText
                id="deviceModel"
                name="deviceModel"
                value={values.device ? values.device.commercialName : ""}
                disabled={true}
              />
            </div>

            {values.serialNumber && (
              <div className="flex flex-column gap-1 mb-3">
                <label htmlFor="serialNumber">{t("quoter.serialNumber")}</label>
                <InputText
                  id="serialNumber"
                  name="serialNumber"
                  value={values.serialNumber}
                  disabled={true}
                />
              </div>
            )}

            <div className="flex flex-column gap-1 mb-3">
              <label htmlFor="repairCost">{t("quoter.repairCost")}</label>
              <InputText
                id="repairCost"
                name="repairCost"
                value={formatPrice(values.repairCost)}
                disabled={true}
              />
            </div>

            {values.repair_costs &&
              values.repair_costs.length > 0 &&
              values.repair_costs[0].repair_type && (
                <div className="flex flex-column gap-1 mb-3">
                  <label htmlFor="repairType">{t("quoter.repairType")}</label>
                  <InputText
                    id="repairType"
                    name="repairType"
                    value={values.repair_costs[0].repair_type.repairName}
                    disabled={true}
                  />
                </div>
              )}

            {values.repair_costs &&
              values.repair_costs.length > 0 &&
              values.repair_costs[0].repair_subtype && (
                <div className="flex flex-column gap-1 mb-3">
                  <label htmlFor="repairSubtype">
                    {t("quoter.repairSubtype")}
                  </label>
                  <InputText
                    id="repairSubtype"
                    name="repairSubtype"
                    value={values.repair_costs[0].repair_subtype.name}
                    disabled={true}
                  />
                </div>
              )}

            <div className="flex flex-column gap-1 mb-3">
              <label htmlFor="finalCost">{t("order.finalCost")}</label>
              <InputNumber
                inputId="finalPrice"
                value={values.finalCost}
                onChange={(e) =>
                  setFieldValue("finalCost", parseFloat(e.value))
                }
                mode="currency"
                currency="ARS"
                locale="es-AR"
                disabled={!isAdmin}
              />
            </div>

            <div className="flex flex-column gap-1 mb-3">
              <label htmlFor="observations">{t("quoter.observations")}</label>
              <InputTextarea
                id="observations"
                value={values.observations || ""}
                style={{ borderRadius: "4px" }}
                onChange={(e) => setFieldValue("observations", e.target.value)}
                autoResize={true}
                rows={4}
                cols={30}
                disabled={!isAdmin}
              />
            </div>

            <div className="flex flex-column gap-1 mb-3">
              <label htmlFor="orderStatus">{t("order.orderStatus")}</label>
              <Dropdown
                id="orderStatus"
                name="orderStatus"
                value={values.orderStatus}
                options={orderStatus.map((option) => ({
                  label: option.name,
                  value: option.id,
                }))}
                onChange={(e) => setFieldValue("orderStatus", e.value)}
                disabled={!isAdmin}
              />
            </div>

            <div className="flex flex-column gap-1 mb-3">
              <label htmlFor="updatedAt">{t("order.updatedAt")}</label>
              <InputText
                id="updatedAt"
                name="updatedAt"
                value={new Date(values.updatedAt).toLocaleDateString()}
                disabled={true}
              />
            </div>
          </div>

          <Accordion className="mb-3">
            <AccordionTab header={t("userInfo.title")}>
              <div className="flex flex-column gap-1 mb-3">
                <InputText
                  id="clientName"
                  name="clientName"
                  value={values.clientName ?? ""}
                  placeholder={t("userInfo.clientName")}
                  disabled={true}
                />
              </div>
              <div className="flex flex-column gap-1 mb-3">
                <InputText
                  id="clientEmail"
                  name="clientEmail"
                  value={values.clientEmail ?? ""}
                  placeholder={t("userInfo.clientEmail")}
                  disabled={true}
                />
              </div>
              <div className="flex flex-column gap-1 mb-3">
                <InputText
                  id="phoneNumber"
                  name="phoneNumber"
                  value={values.phoneNumber ?? ""}
                  placeholder={t("userInfo.phoneNumber")}
                  disabled={true}
                />
              </div>
              <div className="flex flex-column gap-1 mb-3">
                <InputText
                  id="documentNumber"
                  name="documentNumber"
                  value={values.documentNumber ?? ""}
                  placeholder={t("userInfo.documentNumber")}
                  disabled={true}
                />
              </div>
              <div className="flex flex-column gap-1 mb-3">
                <InputText
                  id="street"
                  name="street"
                  value={values.street ?? ""}
                  placeholder={t("userInfo.street")}
                  disabled={true}
                />
              </div>
              <div className="flex flex-column gap-1 mb-3">
                <InputText
                  id="streetNumber"
                  name="streetNumber"
                  value={values.streetNumber ?? ""}
                  placeholder={t("userInfo.streetNumber")}
                  disabled={true}
                />
              </div>
              <div className="flex flex-column gap-1 mb-3">
                <InputText
                  id="location"
                  name="location"
                  value={values.location ?? ""}
                  placeholder={t("userInfo.location")}
                  disabled={true}
                />
              </div>
              <div className="flex flex-column gap-1 mb-3">
                <InputText
                  id="postalCode"
                  name="postalCode"
                  value={values.postalCode ?? ""}
                  placeholder={t("userInfo.postalCode")}
                  disabled={true}
                />
              </div>
              <div className="flex flex-column gap-1 mb-3">
                <InputText
                  id="floor"
                  name="floor"
                  value={values.floor ?? ""}
                  placeholder={t("userInfo.floor")}
                  disabled={true}
                />
              </div>
              <div className="flex flex-column gap-1 mb-3">
                <InputText
                  id="department"
                  name="department"
                  value={values.department ?? ""}
                  placeholder={t("userInfo.department")}
                  disabled={true}
                />
              </div>
            </AccordionTab>
          </Accordion>

          {isAdmin && (
            <div className="p-fluid">
              <Button
                label={t("userInfo.saveChanges")}
                type="submit"
                disabled={isSubmitting}
                className="mb-1"
              />
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default OrderDetail;
