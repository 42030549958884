import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isPendingAction, isRejectedAction } from "../../utils/ActionsUtils";

import { RootState } from "../../app/redux/store";
import { deviceModel } from "../quoter/model/quoterModel";
import {
  cancelOrderAPI,
  confirmOrderAPI,
  doGetDevices,
  doGetOrder,
  doGetOrders,
  doGetOrderStatuses,
  updateOrderAPI,
} from "./api/ordersApi";
import { Order, ordersResponse, OrderStatus } from "./model/ordersModel";

interface OrdersState {
  devices: deviceModel[] | null;
  orderStatus: OrderStatus[];
  loading: boolean;
  success: boolean;
  error: string;
  orders: ordersResponse;
  selectedOrder: Order | null;
  currentPage: number;
  pageSize: number;
  totalRecords: number;
}

const initialState: OrdersState = {
  devices: null,
  orderStatus: [],
  loading: false,
  success: false,
  error: "",
  orders: {
    data: [],
    pagination: {
      page: 1,
      pageCount: 0,
      pageSize: 10,
      total: 0,
    },
  },
  selectedOrder: null,
  currentPage: 1,
  pageSize: 10,
  totalRecords: 0,
};

export const fetchGetOrder = createAsyncThunk(
  "getOrder",
  async (orderId: string) => doGetOrder(orderId),
);

export const fetchGetOrderStatuses = createAsyncThunk(
  "getOrderStatuses",
  async () => doGetOrderStatuses(),
);

export const loadOrders = createAsyncThunk<
  ordersResponse,
  {
    pageSize: number;
    page: number;
    startDate?: string;
    endDate?: string;
    documentNumber?: string | undefined;
    id?: string;
    device?: string;
    deviceType?: string;
    manufacturer?: string;
  },
  { state: RootState }
>(
  "orders/getOrders",
  async ({
    pageSize,
    page,
    startDate,
    endDate,
    documentNumber,
    id,
    device,
    deviceType,
    manufacturer,
  }) => {
    return doGetOrders({
      pageSize,
      page,
      startDate,
      endDate,
      documentNumber,
      id,
      device,
      deviceType,
      manufacturer,
    });
  },
);

export const updateOrder = createAsyncThunk(
  "orders/updateOrder",
  async ({ orderId, orderData }: { orderId: string; orderData: object }) => {
    const response = await updateOrderAPI(orderId, orderData);
    return response;
  },
);

export const fetchCancelOrder = createAsyncThunk(
  "orders/cancelOrder",
  async (orderId: string) => {
    const response = await cancelOrderAPI(orderId);
    return response;
  },
);

export const fetchConfirmOrder = createAsyncThunk(
  "orders/confirmOrder",
  async (orderId: string) => {
    const response = await confirmOrderAPI(orderId);
    return response;
  },
);

export const fetchDevices = createAsyncThunk(
  "quoter/getDevices",
  async ({ searchTerm }: { searchTerm: string }) => {
    return await doGetDevices(searchTerm);
  },
);

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    clearOrders(state) {
      state.orders = initialState.orders;
      state.currentPage = 1;
      state.pageSize = 10;
      state.totalRecords = 0;
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    setPageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadOrders.fulfilled, (state, action) => {
      state.orders = action.payload;
    });
    builder.addCase(fetchDevices.fulfilled, (state, action) => {
      state.devices = action.payload;
    });
    builder.addCase(fetchGetOrder.fulfilled, (state, action) => {
      state.selectedOrder = action.payload;
    });
    builder.addCase(fetchGetOrderStatuses.fulfilled, (state, action) => {
      state.orderStatus = action.payload;
    });
    builder.addMatcher(isPendingAction, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "An error occurred";
    });
  },
});

export const {} = ordersSlice.actions;

export const selectOrdersState = (state: { order: OrdersState }) => state.order;

export default ordersSlice.reducer;
