/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import toast from "react-hot-toast";
import i18n from "../../i18n";
import { store } from "../../redux/store";
import { refreshToken } from "../api/authApi";
import { logOut } from "../authSlice";
import { Auth, AuthService } from "../lib/authService";

let refreshTokenPromise: Promise<Auth> | null;

const onRequest = (
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig => {
  // Skip if already defined
  if (config.headers?.Authorization) {
    return config;
  }

  const token = AuthService.getInstance().getAuthFromLS()?.access_token;
  if (token) {
    config.headers.setAuthorization(`Bearer ${token}`);
  }
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> =>
  Promise.reject(error);

const onResponse = (response: AxiosResponse): AxiosResponse => response;

const onResponseError = async (error: AxiosError): Promise<unknown> => {
  const originalConfig = error.config;
  if (originalConfig && originalConfig.url !== "auth/authenticate") {
    if (error.response?.status === 401) {
      if (originalConfig.url === "auth/refresh-token") {
        store.dispatch(logOut());
        error.message = i18n.t("errors.unauthorized");
        return Promise.reject(error);
      }

      try {
        const rToken = AuthService.getInstance().getLocalRefreshToken();
        if (rToken) {
          if (!refreshTokenPromise) {
            refreshTokenPromise = refreshToken(rToken);
          }

          // eslint-disable-next-line @typescript-eslint/naming-convention
          const { access_token } = await refreshTokenPromise;
          refreshTokenPromise = null;
          AuthService.getInstance().updateLocalAccessToken(access_token);
          originalConfig.headers.setAuthorization(`Bearer ${access_token}`);
          return await axios(originalConfig);
        }

        error.message = i18n.t("errors.unauthorized");
        return await Promise.reject(error);
      } catch (_error) {
        return Promise.reject(_error);
      }
    }

    if (error.response?.status === 403) {
      toast.error(i18n.t("errors.forbidden"));
    }

    return Promise.reject(error);
  }

  return Promise.reject(error);
};

export function setupAuthInterceptorTo(
  axiosInstance: AxiosInstance,
): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}
