import { Action, AnyAction } from "@reduxjs/toolkit";

interface RejectedAction extends Action {
  error: Error;
}

type PendingAction = Action;

export const isRejectedAction = (action: AnyAction): action is RejectedAction =>
  action.type.endsWith("rejected");

export const isPendingAction = (action: AnyAction): action is PendingAction =>
  action.type.endsWith("pending");
