import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatPrice } from "../../../utils/general";

interface Order {
  id: string;
  createdAt: string;
  updatedAt: string;
  device: {
    technicalName: string;
    commercialName: string;
  };
  clientName: string;
  phoneNumber: string;
  finalCost: string;
  orderStatus: string;
  order_status: {
    data: {
      id: number;
    };
  };
}

interface OrdersTableProps {
  orders: {
    data: Order[];
  };
  onViewDetails: (id: string) => void;
  onEditOrder: (id: string) => void;
  onConfirmOrder: (id: string) => void;
  showConfirmCancelOrder: (id: string) => void;
  isAdmin: boolean;
}

const OrdersTable: React.FC<OrdersTableProps> = ({
  orders,
  onViewDetails,
  onEditOrder,
  onConfirmOrder,
  showConfirmCancelOrder,
  isAdmin,
}) => {
  const { t } = useTranslation();

  const actionBodyTemplate = (rowData: Order) => {
    return (
      <div style={{ display: "flex", justifyContent: "center", gap: "1px" }}>
        {rowData && rowData.order_status.data.id === 1 && (
          <Button
            icon="pi pi-check"
            className="p-button-rounded p-button-confirm mr-1"
            tooltip={t("confirmOrder")}
            onClick={() => onConfirmOrder(rowData.id)}
          />
        )}
        {!isAdmin && (
          <Button
            icon="pi pi-eye"
            className="p-button-rounded p-button-success mr-1"
            tooltip={t("viewDetails")}
            onClick={() => onViewDetails(rowData.id)}
          />
        )}
        {isAdmin && (
          <Button
            icon="pi pi-pencil"
            className="p-button-rounded p-button-info  mr-1"
            tooltip={t("Edit")}
            onClick={() => onEditOrder(rowData.id)}
          />
        )}
        {rowData &&
          (rowData.order_status.data.id === 3 ||
            rowData.order_status.data.id === 1) && (
            <Button
              icon="pi pi-trash"
              className="p-button-rounded p-button-warning mr-1"
              tooltip={t("cancelOrder")}
              onClick={() => showConfirmCancelOrder(rowData.id)}
            />
          )}
      </div>
    );
  };

  return (
    <DataTable value={orders.data} dataKey="id">
      <Column field="id" header={t("order.id")} />
      <Column
        field="createdAt"
        header={t("order.createdAt")}
        body={(rowData) => new Date(rowData.createdAt).toLocaleDateString()}
      />
      <Column field="device.technicalName" header={t("order.technicalName")} />
      <Column
        field="device.commercialName"
        header={t("order.commercialName")}
      />
      <Column field="clientName" header={t("userInfo.clientName")} />
      <Column field="phoneNumber" header={t("userInfo.phoneNumber")} />
      <Column
        field="finalCost"
        header={t("order.finalCost")}
        body={(rowData) => formatPrice(rowData.finalCost)}
      />
      <Column
        field="updatedAt"
        header={t("order.updatedAt")}
        body={(rowData) => new Date(rowData.updatedAt).toLocaleDateString()}
      />
      <Column field="orderStatus" header={t("order.orderStatus")} />
      <Column body={actionBodyTemplate} />
    </DataTable>
  );
};

export default OrdersTable;
