import { t } from "i18next";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import React from "react";
import { formatPrice } from "../../../utils/general";

interface Order {
  id: string;
  clientName: string;
  phoneNumber: string;
  createdAt: string;
  updatedAt: string;
  device: {
    technicalName: string;
    commercialName: string;
  };
  finalCost: string;
  orderStatus: string;
  order_status: {
    data: {
      id: number;
    };
  };
}

interface OrderCardProps {
  order: Order;
  onViewDetails: (id: string) => void;
  onEditOrder: (id: string) => void;
  onConfirmOrder: (id: string) => void;
  showConfirmCancelOrder: (id: string) => void;
  isAdmin: boolean;
}

const OrderCard: React.FC<OrderCardProps> = ({
  order,
  onViewDetails,
  onEditOrder,
  onConfirmOrder,
  showConfirmCancelOrder,
  isAdmin,
}) => {
  const formattedPhoneNumber = encodeURIComponent(`+549${order.phoneNumber}`);
  const whatsappLink = `https://wa.me/${formattedPhoneNumber}`;

  const footer = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        <strong>{t("order.orderStatus")}:</strong>
        <div>{order.orderStatus}</div>
      </div>
      <div className="buttonContainerStyle">
        {order && order.order_status.data.id === 1 && (
          <Button
            icon="pi pi-check"
            className="p-button-rounded p-button-confirm small-button"
            // tooltip={t("confirmOrder")}
            onClick={() => onConfirmOrder(order.id)}
          />
        )}
        {!isAdmin && (
          <Button
            icon="pi pi-eye"
            className="p-button-rounded p-button-success small-button"
            // tooltip={t("viewDetails")}
            onClick={() => onViewDetails(order.id)}
          />
        )}
        {isAdmin && (
          <Button
            icon="pi pi-pencil"
            className="p-button-rounded p-button-info small-button"
            // tooltip={t("Edit")}
            onClick={() => onEditOrder(order.id)}
          />
        )}
        {order &&
          (order.order_status.data.id === 3 ||
            order.order_status.data.id === 1) && (
            <Button
              icon="pi pi-trash"
              className="p-button-rounded p-button-warning small-button"
              // tooltip={t("cancelOrder")}
              onClick={() => showConfirmCancelOrder(order.id)}
            />
          )}
      </div>
    </div>
  );

  return (
    <Card
      title={
        <div className="card-title-container">
          <span className="card-title">
            {t("Order")} {order.id}
          </span>
          <span className="card-subtitle">
            {t("order.createdAt")}:{" "}
            {new Date(order.createdAt).toLocaleDateString()}
          </span>
        </div>
      }
      footer={footer}
      className="mb-3 md:w-25rem custom-card-style"
    >
      {order.clientName && (
        <div className="client-name-container mb-1">
          <i className="pi pi-user mr-2" />
          <span className="p-text-bold">{order.clientName}</span>
        </div>
      )}
      {order.phoneNumber && (
        <div className="phone-number-container mb-1">
          <i className="pi pi-whatsapp mr-2" />
          <span
            className="phone-number-link p-text-bold"
            onClick={() => window.open(whatsappLink, "_blank")}
          >
            {order.phoneNumber}
          </span>
        </div>
      )}

      <div className="card-text mb-1">
        {order.device &&
          order.device.technicalName &&
          order.device.commercialName &&
          `${order.device.technicalName} - ${order.device.commercialName}`}
      </div>
      <div className="card-text mb-1">{`${t("order.finalCost")}: ${formatPrice(order.finalCost)}`}</div>
      <div className="card-text mb-1">{`${t("order.updatedAt")}: ${new Date(order.updatedAt).toLocaleDateString()}`}</div>
    </Card>
  );
};

export default OrderCard;
