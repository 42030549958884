/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import {
  Action,
  AnyAction,
  combineReducers,
  configureStore,
  Reducer,
  ThunkAction,
} from "@reduxjs/toolkit";
import quoterReducer from "../../features/quoter/quoterSlice";
import userReducer from "../../features/userInfo/userInfoSlice";
import ordersReducer from "../../features/orders/ordersSlice";
import { authReducer } from "../auth/authSlice";
import { appReducer } from "./appSlice";

const combinedReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  quoter: quoterReducer,
  user: userReducer,
  order: ordersReducer,
});

export type RootState = ReturnType<typeof combinedReducer>;
const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "auth/logOut") {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  devTools: import.meta.env.DEV,
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
