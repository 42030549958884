import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AuthService } from "../../app/auth/lib/authService";
import { AuthUser } from "../../app/auth/models/auth-user";
import { doGetUserInfo, doUpdateUserInfo } from "./api/userInfoApi";

// interface UserState {
//   userInfo: AuthUser | null;
// }

// const initialState: UserState = (() => {
//   const userLS = AuthService.getInstance().getUserFromLS();
//   return {
//     userInfo: userLS || null,
//   };
// })();

export const fetchUserInfo = createAsyncThunk(
  "user/getUserInfo",
  async (_, { rejectWithValue }) => {
    try {
      const response = await doGetUserInfo();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchUpdateUserInfo = createAsyncThunk(
  "user/updateUserInfo",
  async (userData: AuthUser, { rejectWithValue }) => {
    try {
      const response = await doUpdateUserInfo(userData);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const userSlice = createSlice({
  name: "user",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder;
  },
});

// export const selectUserState = (state: { user: UserState }) => state.user;

export default userSlice.reducer;
