import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { login, resetAuth, selectAuth } from "../../authSlice";
import { fetchUserInfo } from "../../../../features/userInfo/userInfoSlice";

export function LoginRedirect() {
  const location = useLocation();
  const urlParams = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const authState = useAppSelector(selectAuth);
  const { user, tokens, error, loginCompleted } = authState;

  useEffect(() => {
    // Successfully logged with the provider
    // Now logging with strapi by using the access_token (given by the provider)
    const access_params = location.search;
    dispatch(login({ access_params }));
  }, [dispatch, location.search]);

  useEffect(() => {
    if (loginCompleted && tokens && user && !user.role) {
      dispatch(fetchUserInfo());
    }

    if (tokens && user && user.role) {
      const redirectTo = urlParams?.from || "/";
      navigate(redirectTo);
    } else if (!tokens) {
      dispatch(resetAuth());
    }
  }, [tokens, user, navigate, dispatch, urlParams?.from, loginCompleted]);

  return <main className="bg-white">{error}</main>;
}
