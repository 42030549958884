export const RouteNewToken = "new";

const RoutesDefinitions = {
  quoter: "/quoter",
  order: {
    orders: "orders",
    orderDetail: ":orderId",
  },
  userInfo: "userInfo",
};

export default RoutesDefinitions;
