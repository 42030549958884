import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchUpdateUserInfo,
  fetchUserInfo,
} from "../../features/userInfo/userInfoSlice";
import { isPendingAction, isRejectedAction } from "../../utils/ActionsUtils";
import type { RootState } from "../redux/store";
import { doLogin, doLogOut } from "./api/authApi";
import { Auth, AuthService } from "./lib/authService";
import { AuthUser } from "./models/auth-user";

interface AuthState {
  loading: boolean;
  success: boolean;
  error: string;
  user?: AuthUser;
  tokens?: Auth;
  loginCompleted: boolean;
}

// Define the initial state using that type
const loadInitialState = (): AuthState => {
  const initialState: AuthState = {
    loading: false,
    success: false,
    loginCompleted: false,
    error: "",
  };

  const authLS = AuthService.getInstance().getAuthFromLS();
  if (authLS) {
    initialState.tokens = authLS;
  }

  const userLS = AuthService.getInstance().getUserFromLS();
  if (userLS) {
    initialState.user = userLS;
  }

  return initialState;
};

type FetchLoginRequest = {
  access_params: string;
};

export const login = createAsyncThunk(
  "auth/login",
  async (request: FetchLoginRequest) => doLogin(request.access_params),
);

export const logOut = createAsyncThunk("auth/logOut", async () => {
  doLogOut();
});

const authSlice = createSlice({
  name: "auth",

  initialState: loadInitialState,

  reducers: {
    resetAuth(state) {
      state.loading = false;
      state.success = false;
      state.error = "";
    },
    updateUser: (state, action) => {
      state.user = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      const tokens = {
        access_token: action.payload.jwt,
        refresh_token: "",
      };
      state.loginCompleted = true;
      const user = action.payload.user;
      AuthService.getInstance().setAuthToLS(tokens, user);
      state.error = "";
      state.loading = false;
      state.tokens = tokens;
      state.user = user;
    });
    builder.addCase(logOut.fulfilled, (state) => {
      AuthService.getInstance().deleteAuthFromLS();
      state.user = undefined;
      state.tokens = undefined;
    });
    builder.addCase(logOut.rejected, (state) => {
      AuthService.getInstance().deleteAuthFromLS();
      state.user = undefined;
      state.tokens = undefined;
    });
    builder.addCase(fetchUpdateUserInfo.fulfilled, (state, action) => {
      state.user = action.payload;
      AuthService.getInstance().setUserToLS(action.payload);
    });
    builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
      state.user = action.payload;
      AuthService.getInstance().setUserToLS(action.payload);
    });
    builder.addMatcher(isPendingAction, (state) => {
      state.error = "";
      state.loading = true;
      state.success = false;
      state.loginCompleted = false;
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.loading = false;
      state.success = false;
      state.loginCompleted = false;
      state.error = action.error.message || "";
    });
  },
});

export const { resetAuth, updateUser } = authSlice.actions;
export const selectAuth = (state: RootState) => state.auth;
export const authReducer = authSlice.reducer;
