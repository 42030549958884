import {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import toast from "react-hot-toast";
import i18n from "../i18n";

const onRequest = (
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig => config;

const onRequestError = (error: AxiosError): Promise<AxiosError> =>
  Promise.reject(error);

const onResponse = (response: AxiosResponse): AxiosResponse => response;

const onResponseError = async (error: AxiosError): Promise<unknown> => {
  if (error.response?.status !== 401 && error.response?.status !== 403) {
    const originalConfig = error.config;
    extractErrorMessage(error);
    if (originalConfig && originalConfig.url?.indexOf("auth") === -1) {
      toast.error(i18n.t(error.message));
    }
  }

  return Promise.reject(error);
};

export function setupErrorInterceptorTo(
  axiosInstance: AxiosInstance,
): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}

function extractErrorMessage(error: AxiosError) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data = error.response?.data as any;
  if (data?.error?.message) {
    error.message = data?.error?.message as string;
  }
}
