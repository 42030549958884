import { createMongoAbility } from "@casl/ability";
import { jwtDecode } from "jwt-decode";
import { AuthService } from "./authService";

export type PermissionsActions =
  | "manage"
  | "create"
  | "read"
  | "update"
  | "delete";
export type PermissionsSubjects = "users" | "roles";

export const ability = createMongoAbility<[PermissionsActions, string]>();

export class PermissionsService {
  static loadPermissionsForUser() {
    const token = AuthService.getInstance().getAuthFromLS()?.access_token;

    if (token) {
      const jwt: { scope: string } = jwtDecode(token);
      const scopeString = jwt.scope;
      if (scopeString && scopeString !== "") {
        const scopes = scopeString.split(" ");
        ability.update(
          scopes.map((scope) => ({ action: "manage", subject: scope })),
        );
      } else {
        ability.update([]);
      }
    }
  }
}
