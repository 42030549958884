import React from "react";
import { Route } from "react-router-dom";
import RoutesDefinitions from "../../routes-definition";
import UserInfo from "./components/userInfo";

export default (
  <Route
    path={RoutesDefinitions.userInfo}
    element={<UserInfo />}
    key="userInfo"
  />
);
